
    import SiteLinks from '../components/SiteLinks.vue';
    import BaseModal from './BaseModal';

    export default {
        name: 'MobileNav',

        components: {
            SiteLinks,
        },

        extends: BaseModal,

        data () {
            return {
                modalName: 'mobileNav',
            };
        },
    };
