
    import { mapActions, mapState } from 'vuex';
    import BaseModal from './BaseModal';

    export default {
        name: 'DropGameScoreboardModal',

        extends: BaseModal,

        data () {
            return {
                score: 0,
                maxScore: 0,
                hasPlayedInstantWinGame: false,
            };
        },

        computed: {
            ...mapState(['game', 'award']),
        },

        i18nOptions: { namespaces: ['adver_game'] },

        async mounted () {
            await this.getGamePlayLimits(({ game: 'advergame' }));
            this.hasPlayedInstantWinGame = this.award.gameLimits?.iwGamePlayedToday;
            await this.getGameScores();

            this.score = this.game.recentScore;
            this.maxScore = this.game.highScore;
        },
        methods: {
            ...mapActions({
                getGamePlayLimits: 'award/getGamePlayLimits',
                getGameScores: 'game/getGameScores',
            }),
            ...mapActions('award', ['generateToken', 'play']),

            async navigateToGameResult () {
                await this.generateToken();
                await this.play();
                const { prizeWon } = this.$store.state.award;
                this.closeModal();

                if (prizeWon) {
                    return this.$router.replace({ path: '/inky' });
                }

                return this.$router.replace({ path: '/janky' });
            },

            backToHub () {
                this.closeModal();
                return this.$router.replace({ path: '/hub' });
            },
        },

    };
