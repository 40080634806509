
import axios from 'axios';
import { reactive } from 'vue';
import api from '../../api';

const getDefaultState = () => reactive({
    recentScore: 0,
    highScore: 0,
});

const state = getDefaultState();

const mutations = {
    setScores (state, data)   {
        for (const key in data) {
            state[key] = data[key];
        }
    },
};

const actions = {
    async getGameScores ({ commit, rootState }) {
        const response = await axios.get(`${api.base}/game/${rootState.profile.id}/:scores`);
        commit('setScores', response.data);
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
