import { resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-error" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_t = _resolveDirective("t")

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _withDirectives(_createElementVNode("h2", null, null, 512), [
      [_directive_t, 'headline_copy']
    ]),
    _withDirectives(_createElementVNode("p", null, null, 512), [
      [_directive_t, 'body_copy']
    ])
  ]))
}