
    import { mapState } from 'vuex';
    import openModal, { MobileNav } from '../modals';
    export default {
        computed: {
            ...mapState(['ui']),
        },

        i18nOptions: { namespaces: ['global'] },

        mounted () {
            // Focus the h1 element to force screen readers back to the top after a navigation.
            // This will ensure screen readers will read the page again.
            // https://a11y-guidelines.orange.com/en/articles/single-page-app/#notify-user-of-page-changes
            this.$router.afterEach(() => this.$refs.h1.focus());
        },

        methods: {
            openMobileNav () {
                openModal(MobileNav);
            },
        },
    };
