
    import { mapState, mapGetters, mapActions } from 'vuex';
    import { OutgoingAffiliate } from '@roc/affiliate';
    import openModal, { PrizeDetails } from '../modals';
    import { openPopup } from '../popup';

    export default {
        name: 'SiteLinks',

        i18nOptions: { namespaces: ['links'] },

        components: {
            OutgoingAffiliate,
        },

        computed: {
            ...mapState([
                'app',
                'profile',
            ]),
            ...mapGetters({
                inPhase: 'app/inPhase',
            }),
        },

        methods: {
            openPrizeDetails () {
                openModal(PrizeDetails);
            },

            openPopup,

            ...mapActions({
                logOut: 'profile/logOut',
            }),
        },
    };

