
// Asynchronous component definitions for lazy-loading routes.
// https://v3.router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const
    CookiesPage = async () => import(
        /* webpackChunkName: 'cookies' */
        '../views/CookiesPage.vue'
    );

export default [
    { path: '/cookies', component: CookiesPage, meta: {
        public: true,
        activeInPhase: ':any',
        activeInMaint: true,
    } },
];
