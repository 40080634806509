
    import { mapState, mapGetters } from 'vuex';
    import { openPopup } from '../popup';
    import SiteLinks from '../components/SiteLinks.vue';

    export default {
        name: 'TheFooter',

        i18nOptions: { namespaces: ['footer', 'links', 'global', 'hub'] },

        components: {
            SiteLinks,
        },

        computed: {
            ...mapState(['app', 'ui']),
            ...mapGetters({
                inPhase: 'app/inPhase',
            }),
        },

        methods: {
            openPopup,
        },
    };

