
// Asynchronous component definitions for lazy-loading routes.
// https://v3.router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const
    DropGamePage = async () => import(
        /* webpackChunkName: 'dropgame' */
        '../views/DropGamePage.vue'
    ),
    MemoryMatchGamePage = async () => import(
        /* webpackChunkName: 'memorygame' */
        '../views/MemoryMatchGamePage.vue'
    ),
    InkyPage = async () => import(
        /* webpackChunkName: 'dropgame' */
        '../views/InkyPage.vue'
    ),
    JankyPage = async () => import(
        /* webpackChunkName: 'dropgame' */
        '../views/JankyPage.vue'
    );

export default [
    { path: '/inky', redirect: () => '/game/inky' },
    { path: '/janky', redirect: () => '/game/janky' },
    { path: '/game/drop',  component: DropGamePage },
    { path: '/game/memory',  component: MemoryMatchGamePage },
    { path: '/game/inky',  component: InkyPage },
    { path: '/game/janky', component: JankyPage },
    { path: '/game/*', redirect: (to) => '/hub' },
];
