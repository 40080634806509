import { resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  class: "modal memoryMatchWinModal",
  role: "dialog"
}
const _hoisted_2 = {
  key: 0,
  class: "mm-copy"
}
const _hoisted_3 = {
  key: 1,
  class: "mm-copy"
}
const _hoisted_4 = {
  key: 2,
  class: "modal-disclaimer"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_t = _resolveDirective("t")

  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: _cache[1] || (_cache[1] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _withDirectives(_createElementVNode("h2", null, null, 512), [
          [_directive_t, 'win_modal.heading']
        ]),
        (!$data.noPlaysLeft)
          ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, null, 512)), [
              [_directive_t, 'win_modal.body_play_left']
            ])
          : _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, null, 512)), [
              [_directive_t, 'win_modal.body_no_play_left']
            ]),
        _withDirectives(_createElementVNode("button", {
          href: "#",
          "data-e2e": "error--close",
          class: "btn",
          onClick: _cache[0] || (_cache[0] = (...args) => ($options.backToHubClick && $options.backToHubClick(...args)))
        }, null, 512), [
          [_directive_t, 'buttons:back_to_hub']
        ]),
        (!$data.noPlaysLeft)
          ? _withDirectives((_openBlock(), _createElementBlock("p", _hoisted_4, null, 512)), [
              [_directive_t, 'win_modal.disclaimer']
            ])
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}