
import store from '../store/index';

// Asynchronous component definitions for lazy-loading routes.
// https://v3.router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const
    OptOutPage = async () => import(
        /* webpackChunkName: 'optout' */
        '../views/EmailOptOutPage.vue'
    ),
    DuplicateAccountPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/DuplicateAccountPage.vue'
    ),
    IneligibleAgePage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/IneligibleAgePage.vue'
    ),
    LimiterPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/LimiterPage.vue'
    ),
    RegConfirmationPage = async () => import(
        /* webpackChunkName: 'regConfirm' */
        '../views/RegConfirmationPage.vue'
    ),
    HubPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/HubPage.vue'
    ),
    LoginPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/LoginPage.vue'
    ),
    RegisterPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/RegisterPage.vue'
    ),
    EmailVerificationPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/EmailVerificationPage.vue'
    );

export default [
    { path: '/', redirect: (to) => {
        // Used to send server-side errors to the '/error' page.
        if (store.state.app.defaultRoute) {
            return store.state.app.defaultRoute;
        }
        // Default routes for logged-in users.
        else if (
            store.getters['profile/loggedIn'] &&
            !store.getters['profile/isSessionExpired']
        ) {
            console.log('logged in');

            return '/hub';
        }
        // Default routes for returning users (reg cookie) who are not logged-in.
        else if (store.getters['profile/returning']) {
            return '/login';
        }

        // Default route for a user we know nothing about.
        return '/login';
    } },
    { path: '/login', component: LoginPage, meta: { public: true } },
    { path: '/register', component: RegisterPage, meta: { public: true } },
    { path: '/registration-confirmation', component: RegConfirmationPage },
    { path: '/optout', component: OptOutPage, meta: { public: true } },
    { path: '/hub', component: HubPage },
    { path: '/limiter', component: LimiterPage },
    { path: '/ineligible_age', component: IneligibleAgePage, meta: { public: true } },
    { path: '/dup_account', component: DuplicateAccountPage, meta: { public: true } },
    { path: '/email-verification', component: EmailVerificationPage, meta: { public: true } },
    { path: '/logout', redirect: (to) => {
        store.dispatch('profile/logOut');
        return '/login';
    } },
];
