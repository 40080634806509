
import axios from 'axios';
import { reactive } from 'vue';
import api from '../../api';
import { setRecaptchaV3Challenge } from '../../recaptcha';

const getDefaultState = () => reactive({
    awards: null,
    prizeWon: null,
    gameLimits: {},
    hubLimits: {},
});

const state = getDefaultState();

const getters = {};

const mutations = {
    updateAwards (state, awards) {
        state.awards = awards;
    },
    updatePrizeWon (state, prizeWon) {
        state.prizeWon = prizeWon;
    },
    updateGameStoreState (state, data) {
        for (const key in data) {
            state[key] = data[key];
        }
    },
    setGameLimits (state, dataObject)   {
        for (const key in dataObject) {
            state.gameLimits[key] = dataObject[key];
        }
    },

    updateHubLimits (state, dataObject) {
        for (const key in dataObject) {
            state.hubLimits[key] = dataObject[key];
        }
    },
};

const actions = {
    async hubAward ({ dispatch, commit }, awardId) {
        try {
            await dispatch('awardEvent', {
                event: awardId,
            });
            commit('updateHubLimits', {
                [awardId]: true,
            });
        }
        catch (err) {
            console.error('error awarding recipe click', awardId);
            throw err;
        }

    },
    async getHubLimits ({ commit }) {
        try {
            const response = await axios.get(`${api.base}/awards/hub-actions-status`);
            const hubLimits = {};
            for (const key in response.data.result) {
                hubLimits[key] = response.data.result[key].isLimited;
            }
            commit('updateHubLimits', { ...hubLimits });
        }
        catch (err) {
            if (err.response?.data?.awards?.isLimited) {
                return;
            }
            console.error(
                'error generating token',
                err.message,
                err,
            );
            throw err;
        }
    },
    async generateToken ({ dispatch }) {
        try {

            const response = await dispatch('awardEvent', {
                event: 'generateToken',
            });
            return response;
        }
        catch (err) {
            console.error(
                'error generating token',
                err.message,
                err,
            );
            throw err;
        }
    },

    async getGamePlayLimits ({ commit }, { game }) {
        const response = await axios.get(`${api.base}/game/test-play-limits/${game}`);
        commit('setGameLimits', response.data);
    },

    async getAwards ({ dispatch }) {
        return dispatch('makeCall', {
            type: 'get',
            endpoint: 'awards',
        });
    },

    async getButtonLimit ({ dispatch, commit }, type) {
        const response = await dispatch('makeCall', {
            endpoint: `game/test-play-limits/${type}`,
            type: 'get',
        });

        const { success, ...data } = response.data;

        commit('updateHubLimits', {
            [type]: data,
        });
    },

    async enforceLimit ({ dispatch }, type) {
        await dispatch('makeCall', {
            endpoint: 'game/enforce-play-limits',
            data: {
                game: type,
            },
        });
    },

    async awardEvent ({ dispatch }, { event }) {
        const data = {};
        await setRecaptchaV3Challenge({ data, action: 'award' });
        return dispatch('makeCall', {
            endpoint: `awards/events/${encodeURIComponent(event)}:award`,
            data,
        });
    },

    async play ({ dispatch, state }) {
        const data = {};
        await setRecaptchaV3Challenge({ data, action: 'play' });
        await dispatch('makeCall', {
            endpoint: 'awards/:play',
            data,
        });
        return state.prizeWon;
    },

    async makeCall ({ commit }, {
        type = 'post',
        endpoint,
        data,
    }) {
        let response;

        try {
            response = await axios[type](`${api.base}/${endpoint}`, data);
        }
        catch (err) {
            if (err.response?.status === 429) {
                // User was only limited, carry on
                ({ response } = err);
            }
            else {
                console.error(
                    `error making ${endpoint} call`,
                    err.message,
                    err,
                );

                throw err;
            }
        }

        if (response.data?.awards !== undefined) {
            commit('updateAwards', response.data.awards);
        }
        if (response.data?.prizeWon !== undefined) {
            commit('updatePrizeWon', response.data.prizeWon);
        }

        return response;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
