
    import BaseModal from './BaseModal';

    export default {
        name: 'PrizeDetails',

        i18nOptions: { namespaces: ['modal_content'] },

        extends: BaseModal,

        props: {
            // Note: if you pull these values from copydeck, you need to make
            // sure the namespace is loaded before we need this component, ideally at
            // app startup, since the user may not have a viable Internet connection
            // when this modal appears.
            headline: {
                type: String,
                default: 'Prize Details.',
            },
            body: {
                type: String,
                default: 'Body Copy.',
            },
        },

        data () {
            return {
                modalName: 'prize-details',
            };
        },
    };
