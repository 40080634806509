import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "wrapper text-align-center"
}
const _hoisted_2 = {
  id: "main-section",
  role: "main"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TheHeader = _resolveComponent("TheHeader")
  const _component_router_view = _resolveComponent("router-view")
  const _component_TheFooter = _resolveComponent("TheFooter")

  return (_ctx.app.phase != 'pre-launch')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_TheHeader),
        _createElementVNode("main", _hoisted_2, [
          _createVNode(_component_router_view, { class: "content" })
        ]),
        (_ctx.ui.pageName != 'faq')
          ? (_openBlock(), _createBlock(_component_TheFooter, { key: 0 }))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}