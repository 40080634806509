
    import { mapState } from 'vuex';
    import BaseModal from './BaseModal';

    export default {
        name: 'MemoryMatchWinModal',
        extends: BaseModal,
        data () {
            return {
                noPlaysLeft: false,
            };
        },
        computed: {
            ...mapState(['award']),
        },
        i18nOptions: { namespaces: ['memory_match_game'] },
        async mounted () {
            this.noPlaysLeft = this.award.hubLimits.memory_match.gamePlayedToday;
        },
        methods: {
            backToHubClick () {
                this.closeModal();
            },
        },
    };
